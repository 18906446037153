import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import router from '@/investor/router.js'


// function setCookie(cname, cvalue, exdays) {
//   var d = new Date();
//   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//   var expires = "expires="+d.toUTCString();
//   document.cookie = cname + "=" + cvalue + ";" + expires;
// }
//
// function getCookie(cname) {
//   var name = cname + "=";
//   var ca = document.cookie.split(';');
//   for(var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) == ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

// var fftoken = getCookie('fftoken')
// console.log(fftoken)

Vue.use(Vuex)

var plugins = process.env.VUE_APP_DEBUG === 'true' ? [] : [createPersistedState({ key: HASH })]
export default new Vuex.Store({
  plugins: plugins,
  state: {
    app: 'INVESTOR',
    company: {},
    token: '',
    userEmail: '',
    loginTitle: 'Impact Benchmark',
    loginTitle2: 'Investor Dashboard',
    debugMode: false,
    reports: [],
    latest: [],
    incompleteCompanies: [],
    totalImpacts: 0,
    activeReport: false,
    activeFullReport: false,
    showSidebar: true,
  },
  mutations: {
    setToken(state, token) {
      // fftoken = token
      state.token = token
      // setCookie('fftoken', token, 30)
      Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    logout(state) {
      state.token = ''
      // setCookie('fftoken', '', 0)
      router.push({ path: '/login' })
    },
    login(state, data) {
      this.commit('setToken', data.access_token)
      state.welcome = data.welcome
      state.showSidebar = true
      router.push({ path: '/' })
    },
    setUserEmail(state, value) {
      state.userEmail = value
    },
    showFullReport(state, id) {
      state.activeReport = id
      state.activeFullReport = true
      router.push({ path: '/reports' }).catch(()=>{})
    },
    hideFullReport(state) {
      state.activeFullReport = false
    },
    toggleactiveReport(state, id) {
      state.activeReport = id === state.activeReport ? null : id
    },
    // setactiveReport(state, id) {
    // },
    setReports(state, data) {
      state.reports = data.companies
      state.incompleteCompanies = data.incomplete_companies
      state.latest = data.latest
      state.totalImpacts = data.total_impacts
    },
    setShowSidebar(state, status) {
      state.showSidebar = status
    },
    setCompany(state, company) {
      state.company = company
    },
  },
  actions: {
    getReports: function(state, id) {
      Vue.prototype.$http.get('investor/reports').then((response) => {
        state.commit('setReports', response.data)
      })
    },
  },
  modules: {},
  getters: {},
})
